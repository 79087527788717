.intro-text {
  position: relative;
  box-sizing: border-box;
  padding: 2em 1em;
  background: $white;
  flex: 1;
  box-shadow: $block-shadow-large;

  @include mq($mobileBreakpoint) {
    padding: 3em;
    margin: 0 auto 2em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    margin: 5em 0 0;
    min-width: 58%;
    max-width: 58%;
    margin-right: 2%;
  }

  @include mq(1020) {
    min-width: 57%;
    max-width: 57%;
    margin-right: 3%;
  }

  @include mq(1216) {
    min-width: 47.6em;
    margin-top: 7em;
  }

  @include mq($fourColBreakpoint) {
    margin-right: 2em;
  }

  .intro-text__heading {
    margin: 0;
    display: block;
    font-family: $font-bold;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 98%;
    color: $black;

    @include mq($mobileBreakpoint) {
      font-size: 2.3em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      font-size: 2.4em;
    }

    @include mq($largeBreakpoint) {
      font-size: 2.5em;
    }

    span {
      display: block;
      color: $grey-text;
    }

    @at-root .lang-en .input-wrapper .input-header .input-header__heading {

      @include mq($largeBreakpoint) {
        font-size: 4em;
        max-width: 14.5em;
        flex: 1.1;
      }
    }
  }

  .intro-text__description {
    margin: 0.7em 0 0;
    display: block;
    font-family: $font-standard;
    color: $grey-dark-text;
    font-size: 0.95em;
    line-height: 1.4em;

    @include mq($mobileBreakpoint) {
      margin-top: 1em;
      font-size: 1.1em;
      line-height: 1.5em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      flex: 1;
      margin-top: 1.2em;
      font-size: 1.05em;
    }

    @include mq($largeBreakpoint) {
      margin-top: 1.5em;
      font-size: 1.1em;
    }

    b {
      font-family: $font-bold;
      font-weight: bold;
      display: block;
    }
  }
}