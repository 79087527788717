.datetime-picker {
  position: relative;
  font-family: $font-standard;
  -webkit-font-smoothing: antialiased;

  label {
    max-width: 14em;

    @include mq($smallMobileBreakpoint) {
      max-width: inherit;
    }
  }
}

#datetime-input {
  padding: 2.6em 1em .8em;

  @include mq($smallMobileBreakpoint) {
    padding: 1.4em 1em .6em
  }
}

.datetime-picker * {
  box-sizing: border-box;
}

.datetime-picker .picker-wrap {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 280px;
  margin-top: 2px;
  background-color: #fff;
  box-shadow: 0 0 6px #ccc;
  top:3.5em;

  @include mq($mobileBreakpoint) {
    top:4.2em;
  }

  @include mq($removeMobileMenuBreakPoint) {
    top:4.4em;
    left: 0.5em;
  }
}

.datetime-picker table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  font-size: 13px;
}

.datetime-picker tr {
  height: 34px;
  border: 0 none;
}

.datetime-picker th, .datetime-picker td {
  user-select: none;
  width: 34px;
  height: 34px;
  padding: 0;
  border: 0 none;
  line-height: 34px;
  text-align: center;
}

.datetime-picker td {
  cursor: pointer;
}

.datetime-picker td:hover {
  background-color: $white;
}

.datetime-picker td.date-pass, .datetime-picker td.date-future {
  color: #aaa;
}

.datetime-picker td.date-active {
  background-color: #ececec;
  color: $brand-color;
}

.datetime-picker .date-head {
  background-color: $brand-color;
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.datetime-picker .date-days {
  color: $brand-color;
  font-size: 14px;
}

.datetime-picker .show-year {
  display: inline-block;
  min-width: 62px;
  vertical-align: middle;
}

.datetime-picker .show-month {
  display: inline-block;
  min-width: 28px;
  vertical-align: middle;
}

.datetime-picker .btn-prev,
.datetime-picker .btn-next {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
  vertical-align: middle;
}

.datetime-picker .btn-prev:hover,
.datetime-picker .btn-next:hover {
  background: rgba($brand-color, 0.5);
}