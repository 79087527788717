// Default colors
$black:#000;
$white:#fff;
$grey: #e2e2e2;
$grey-dark: #222222;
$grey-text: #737373;
$grey-dark-text: #333;

//Project colors
$brand-color: #b41730;
$brand-color-2: #00233c;

//System colors
$positive: #5aa253;
$negative: #e75858;
$informative: #1e88c5;
$warning: #e7a713;

//border radius
$r:4px;
$r-btn:6px;

//box-shadows
$block-shadow: 0 1px 2px rgba($black,.04), 0 2px 10px rgba($black, 0.08);
$block-shadow-large: 0 2px 4px rgba($black, 0.05), 0 12px 30px rgba($black, 0.08);

// Font family settings
$font-standard: 'via_officeregular', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-bold: 'via_officebold', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-menu: 'via_expertbold', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

//breakpoints
$smallMobileBreakpoint: 360;
$mobileBreakpoint:600;
$removeMobileMenuBreakPoint: 992;
$largeBreakpoint:1200;
$fourColBreakpoint:1350;
$ExtralargeBreakpoint:1600;