.drop-down {
  margin: 0 auto;
  position: relative;

  &:after {
    background: url("/../assets/img/icon-arrow-d.svg") no-repeat;
    content: '';
    position: absolute;
    top: auto;
    left: auto;
    bottom: 20px;
    right: 1.4em;
    margin: auto;
    width: 1em;
    height: 0.8em;
    pointer-events: none;

    @include mq($smallMobileBreakpoint) {
      right: 1.5em;
    }

    @include mq($mobileBreakpoint) {
      bottom: 24px;
      width: 20px;
      height: 13px;
      right: 1.5em;
    }
  }

  .drop-down__select {
    margin: 0 auto;
    border-top-color: rgba($grey,0.5);
    padding: 2.6em 1em .8em;
    display: block;
    width: 100%;
    font-size: 1em;
    color: $black;
    background: $white;
    box-shadow: 0 1px 1px rgba($black,0.1),0 4px 4px rgba($black,0.05);
    cursor: pointer;
    border: 1px solid transparent;
    box-sizing: border-box;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: background 200ms ease, color 200ms ease, border 280ms ease;
    border-radius: 0;

    @include mq($smallMobileBreakpoint) {
      padding: 1.548em 1em 0.8em;
    }

    @include mq($removeMobileMenuBreakPoint) {
      border-radius: 0;
      border-top-color: transparent;
    }

    &::-ms-expand {
      display: none;
    }

    &::-ms-value {
      background: transparent;
      color: $brand-color;
    }

    @include mq($mobileBreakpoint) {
      font-size: 1.2em;
    }

    &:hover {
      background: $white;
      color: $grey-dark-text;
      transition: background 200ms ease, color 200ms ease;

      &::-webkit-input-placeholder {
        color: $brand-color;
        transition: color 200ms ease;
      }

      &::-moz-placeholder {
        color: $brand-color;
        transition: color 200ms ease;
      }

      &:-ms-input-placeholder {
        color: $brand-color;
        transition: color 200ms ease;
      }
    }

    &:focus {
      color: $grey-dark-text;
    }

    &:focus:hover {
      color: $grey-dark-text;
      background: $white;
      transition: background 200ms ease, color 200ms ease, border 280ms ease;
    }

  }

  .drop-down__label {
    position: absolute;
    top: 1.2em;
    font-family: $font-standard;
    z-index: 1;
    left: 1.3em;
    font-size: 0.8em;
    color: $brand-color;
    -webkit-font-smoothing: antialiased;
    transform: translate3d(0,-0.3em,0);
    letter-spacing: 0.015em;
    pointer-events: none;
    max-width: 18em;

    @include mq($smallMobileBreakpoint) {
      max-width: 40em;
    }

    @include mq($mobileBreakpoint) {
      top: 1.45em;
      left: 1.4em;
      transform: translate3d(0,-0.5em,0);
    }

    @at-root .lang-en .drop-down .drop-down__label {

      @include mq(410) {
        max-width: 27em;
      }


      @include mq($mobileBreakpoint) {
        max-width: 40em;
      }
    }
  }
}