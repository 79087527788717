.msg-bar {
  position: fixed;
  background: rgba(tint($black,70%),0.85);
  color: $black;
  width: 100%;
  top:auto;
  right:0;
  bottom:0;
  left:0;
  padding: 16px 24px;
  font-size: 0.9em;
  margin: 0;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
  font-family: $font-standard;

  &.msg-bar--hide {
    animation: msg-hide 350ms both;
  }

  &.msg-bar--show {
    animation: msg-show 350ms both;
  }

  &.msg-bar--success {
    color: shade($positive,10%);
    background: rgba(tint($positive,70%),0.85);
  }

  &.msg-bar--negative {
    color: $negative;
    background: rgba(tint($negative,70%),0.85);
  }

  &.msg-bar--info {
    color: shade($informative,10%);
    background: rgba(tint($informative,80%),0.85);
  }

  &.msg-bar--warning {
    color: shade($warning,20%);
    background: rgba(tint($warning,80%),0.85);
  }
}