.label-checkbox {
  position: relative;

  &.label-checkbox--dark-ui {

    label {
      color: $white;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      .label-checkbox__box {
        background: $white;
        border-color: transparent;
      }

      &:hover {
        color: $white;
  
        .label-checkbox__box {
          border-color: transparent;
          background: rgba($white,0.8);
          transition: background 120ms ease;
        }
      }
  
    }

    input[type=checkbox] {
      display:none;

      &:checked ~ label {
        color: $white;

        .label-checkbox__box {
          background: $white;
          transition: background 120ms ease;
        }
      }
    }
  }

  label {
    font-family: $font-standard;
    margin: 1em 0 0.2em 2.3em;
    font-size: 0.75em;
    line-height: 140%;
    color: $grey-dark-text;
    display: block;
    cursor:pointer;
    box-sizing: border-box;

    &:hover {
      color: $black;

      .label-checkbox__box {
        border-color: $black;
        background: rgba($white,0.5);
        transition: background 120ms ease;
      }
    }

    span {
      display:block;
      position:absolute;
      left:0;
      transition: transform 150ms ease;
    }

    .label-checkbox__circle {
      background:transparent;
      left:-30px;
      top:-26px;
      height:80px;
      width:80px;
      z-index:1;
      border-radius:50%;
    }

    .label-checkbox__box {
      margin-top: 0.35em;
      border:1px solid $grey-dark-text;
      background: rgba($white,0.2);
      height:20px;
      width:20px;
      z-index:2;
      transition: background 120ms ease;
    }

    .label-checkbox__check {
      top: 6px;
      left: 8px;
      bottom: auto;
      width: 4px;
      height: 12px;
      border: 3px solid $positive;
      border-top: none;
      border-left: none;
      opacity:0;
      z-index:3;
      transform:rotate(180deg);
    }
  }

  input[type=checkbox]   {
    display:none;

    &:checked ~ label {
      color: rgba($black,0.7);

      .label-checkbox__circle {
        animation: grow-circle 350ms ease;
      }

      .label-checkbox__box {
        background: $white;
        transition: background 120ms ease;
      }

      .label-checkbox__check {
        opacity: 1;
        transform: scale(1) rotate(45deg);
      }
    }
  }

}