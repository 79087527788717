.content-page {
  position: relative;
  padding-top: 4em;
  box-sizing: border-box;

  .content-page__wrapper {
    background: $white;
    padding: 1em;
    display: block;
    box-sizing: border-box;
    border-top: 1px solid rgba($grey, 0.5);

    @include mq($mobileBreakpoint) {
      padding: 1.5em;
    }

    @include mq(800) {
      padding: 2em;
      max-width: 750px;
      margin: 1em auto;
      box-shadow: 0 1px 1px rgba($black,0.1),0 4px 4px rgba($black,0.05);
    }

    @include mq($removeMobileMenuBreakPoint) {
      margin: 1em auto 4em;
    }
  }

  h1 {
    font-family: $font-bold;
    font-size: 1.5em;
    letter-spacing: 0.02em;
    margin-top: 0.2em;

    @include mq($mobileBreakpoint) {
      font-size: 2.5em;
      color: $brand-color;
    }
  }

  h2 {
    margin-top: 2em;
    font-family: $font-standard;
    font-size: 1.1em;

    @include mq($mobileBreakpoint) {
      font-size: 1.2em;
    }
  }

  p {
    font-family: $font-standard;
    line-height: 140%;

    @include mq($removeMobileMenuBreakPoint) {
      line-height: 150%;
    }
  }

  .cookie-stats {
    width: 100%;
    border: 1px solid #e7e7e7;

    .cookie-stat__purpose {
      min-width: 16em;

      @include mq($mobileBreakpoint) {
        min-width: 12em;
      }
    }
  }

  table {
    display: block;
    margin:0;
    padding:0;
    background:none;
    border: none;
    border-collapse:collapse;
    border-spacing:0;
    width: 100%;
    overflow: auto;
  }

  td {
    font-family: $font-standard;
  }

  thead td {
    padding: 2px;
    box-sizing: border-box;
    background: #fafafa;
    border: none;
    border-spacing: 0;
    min-width: 4.5em;

    @include mq($mobileBreakpoint) {
      padding: 1em;
    }
  }

  tbody {

    tr {
      border-bottom: 1px solid #e7e7e7;
    }

    td {
      min-width: 4.5em;
      padding: 0.5em;

      @include mq($mobileBreakpoint) {
        padding: 1em;
      }
    }
  }

  .return-btn {
    display: block;

    @include mq(800) {
      max-width: 750px;
      margin: 1em auto;
    }

    a {
      padding: 1em;
      display: block;
      @include chrisCoyier;

      @include mq(800) {
        padding: 1em 0;
        max-width: 18em;
      }

      &:hover {

        svg path {
          fill: $brand-color;
        }

        .return-button__text {
          color: $brand-color;
        }
      }
    }

    svg {
      margin-top: 0.1em;
      float: left;
      display: block;
      width: 10px;
      min-width: 10px;
      max-width: 10px;
      height: 16px;
      min-height: 16px;
      max-height: 16px;

      path {
        fill: $brand-color;
      }
    }

    .return-button__text {
      margin: 0 0 0 0.5em;
      float: left;
      display: block;
      font-size: 1.2em;
    }
  }
}