.coi-banner__page {
    font-family: $font-standard;
}

.coi-banner__page p {
    line-height: 150% !important;
}

.coi-banner__page p strong {
    color: $black !important;
}

.coi-banner__page .coi-banner__headline {
    font-family: $font-bold !important;
    font-weight: bold !important;
}

.coi-banner__page .coi-banner__maintext {
    line-height: 150% !important;
}