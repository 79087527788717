.spinner {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 10em;
    height: 10em;
    z-index: 1000;
    animation: fade-in 1s ease both;

    .spinner__train {
        animation: spinner 1.2s linear infinite;
        transform-origin: center center;
    }
}