.working {
  position: relative;
  pointer-events: none;

  &:before {
    content: '';
    width: 11em;
    height: 11em;
    display: block;
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin: auto;
    z-index: 999;
    background: $white;
    pointer-events: none;
    border-radius: 50%;
    box-shadow: $block-shadow-large;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top:0;
    right:0;
    bottom:0;
    left:0;
    margin: auto;
    z-index: 998;
    background: rgba($white,0.75);
    opacity: 0;
    animation: fade-in-grow-mobile 800ms linear both;
    pointer-events: none;

    @include mq($mobileBreakpoint) {
      animation: fade-in-grow 500ms linear both;
    }
  }

  .spinner {
    display: block;
  }
}