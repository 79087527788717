.mobile-menu {
    width: 6em;
    height: 3em;
    position: absolute;
    top: 0;
    right: auto;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    flex-flow: nowrap row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    @include mq($mobileBreakpoint) {
      left: 0.5em;
    }

    @include mq(1216) {
      left: -0.5em;
    }
  
    .mobile-menu__hamburger {
      position:relative;
      width: 3em;
      height: 3em;
      transform:rotate(0deg);
      transition: transform 400ms ease;
  
      &:hover {
  
        .mobile-menu__hamburger__bar {
          background: $brand-color;
          transition: color 150ms ease;
        }
      }
  
      .mobile-menu__hamburger__bar {
        overflow: auto;
        margin: auto;
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        width: 28px;
        height: 4px;
        background: $brand-color;
        transition: color 500ms ease, transform 150ms ease;
  
        @at-root .showMenu .mobile-menu .mobile-menu__hamburger .mobile-menu__hamburger__bar {
  
          &.mobile-menu__hamburger__bar--top {
            transform:rotate(45deg);
            background: $brand-color;
            transition: transform 250ms ease;
          }

          &.mobile-menu__hamburger__bar--middle {
            transform: translate3d(100px,0,0);
            background: $brand-color;
            opacity: 0;
            transition: transform 250ms ease, opacity 250ms ease;
          }
  
          &.mobile-menu__hamburger__bar--bottom {
            transform:rotate(-45deg);
            background: $brand-color;
            transition: transform 250ms ease;
          }
        }
  
        &.mobile-menu__hamburger__bar--top {
          transform: translateY(-8px);
          transition: transform 250ms ease;
        }

        &.mobile-menu__hamburger__bar--middle {
          opacity: 1;
          transition: transform 250ms ease, opacity 250ms ease;
        }

  
        &.mobile-menu__hamburger__bar--bottom {
          transform: translateY(8px);
          transition: transform 250ms ease;
        }
      }
    }

    .mobile-menu__text {
      font-family: $font-menu;
      color: $brand-color;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }