.main-header {
    position: fixed;
    width: 100%;
    background: $white;
    color: $white;
    z-index: 10;
    box-shadow: $block-shadow;
    height: 70px;
    display: flex;
    flex-flow: nowrap row;
    justify-content: space-between;
    align-items: center;

    &.headroom--not-top {
      transform: translate3d(0,0,0);
      transition: transform 250ms ease;
    }
  
    &.headroom--not-top.headroom--pinned {
      transform: translate3d(0,0,0);
      transition: transform 250ms ease;
    }
  
    &.headroom--unpinned {
      transform: translate3d(0,-100%,0);
      transition: transform 250ms ease;
    }

    .main-header__content {
      @extend .wrapper;
      position: relative;
      padding-top: 1em;
      padding-bottom: 1em;
      width: 100%;
    }

    .main-nav {
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 200ms ease;
      pointer-events: none;

      ul {
        padding: 0;
        transform: translate3d(0,-100%,0);
        transition: 200ms transform ease;

        li a {
          display: block;
          padding: 1em;
          box-sizing: border-box;
          border-bottom: 1px solid tint($grey-text, 65%);
          font-size: 1.2em;
          color: $grey-text;
          text-decoration: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          &:hover {
            color: $brand-color;
          }
        }
      }

      @at-root .showMenu .main-header .main-nav {
        display: block;
        position: absolute;
        top: 3.2em;
        right: 0;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: $white;
        overflow-y: auto;
        pointer-events: inherit;
        z-index: 9;
        opacity: 1;
        transition: opacity 110ms ease;

        @include mq($removeMobileMenuBreakPoint) {
          box-shadow: $block-shadow-large;
          height: auto;
          max-width: 20em;
          min-height: inherit;
          right: auto;
        }

        ul {
          transform: translate3d(0,0,0);
          transition: 200ms transform ease;
        }
      }
    }

    .main-header__logo {
        position: relative;
        width: 8em;
        margin: 0 0 0 2.5em;
        display: block;
        z-index: 1;

        @include mq($mobileBreakpoint) {
          margin-left: 3em;
          margin-top: 0.2em;
        }
    }

    .top-right {
      position: absolute;
      top: 0;
      right: 1em;
      bottom: 0;
      left: 0;
      margin: auto;
      display: flex;
      display: flex;
      flex-flow: nowrap row;
      justify-content: flex-end;
      align-items: center;
    }
  
    .dsb-logo {
      margin: 0 0 0 1em;
      align-self: center;
      width: 2.3em;
      height: 2.3em;
  
      @include mq($removeMobileMenuBreakPoint) {
        margin-left: 1.2em;
        width: 2.8em;
        height: 2.8em;
      }
    }

    .dot-logo {
      margin: 0 0 0 1em;
      align-self: center;
      width: 7em;
      height: 2em;
  
      @include mq($removeMobileMenuBreakPoint) {
        margin-left: 1.2em;
        width: 7.8em;
        height: 2.8em;
      }
    }
  }