.lang-switch {
  position: relative;
  width: 2em;
  height: 2em;
  overflow: hidden;
  border-radius: $r-btn;
  box-shadow: 0 1px 1px rgba($black,0.2);
  transform: translate3d(0, 0);
  transition: transform 110ms ease;

  @include mq($removeMobileMenuBreakPoint) {
    width: 2.5em;
    height: 2.5em;
  }

  &:hover {
    box-shadow: 0 1px 1px rgba($black,0.25);
    transform: translate3d(0.5em, 0);
    transition: transform 110ms ease;
  }

  .lang-switch__flag {

    &.lang-switch__flag--uk img {
      left: -0.5em;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      min-width: 3em;

      @include mq($removeMobileMenuBreakPoint) {
        min-width: 3.5em;
        height: 100%;
      }
    }
  }
}