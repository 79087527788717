.wrapper {
  position: relative;
  display: block;
  padding: 0 0.5em;
  box-sizing: border-box;

  @include mq($smallMobileBreakpoint) {
    padding: 0 1em;
  }

  @include mq($largeBreakpoint) {
    margin: 0 auto;
    max-width: 1200px;
  }
  
  @include mq($fourColBreakpoint) {
    padding: 0 2em;
    max-width: 1250px;
  }

  @include mq(1280) {
    padding: 0;
  }
}