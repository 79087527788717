.suggestion-list-component {
  position: relative;

  &:after {
    background: url("/../assets/img/rejseplanen-app-logo.svg") no-repeat;
    content: '';
    position: absolute;
    top: 0.8em;
    left: auto;
    bottom: auto;
    right: 0.5em;
    margin: auto;
    width: 1.7em;
    height: 1.7em;
    pointer-events: none;
    box-shadow: 0 1px 5px rgba($black, 0.1);
    border-radius: $r;

    @include mq($mobileBreakpoint) {
      top: 1.2em;
      right: 0.8em;
      width: 1.8em;
      height: 1.8em;
    }
  }


  &.open {
    input {
      border-radius: $r $r 0 0;
      border-bottom: 1px solid $grey;
    }

    .suggestion-list {
      display: block;
    }

    &.scroll .suggestion-list {
      @include mq($removeMobileMenuBreakPoint) {
        min-height: 11em;
        max-height: 11em;
        overflow: auto;
      }
    }
  }

  ul.suggestion-list {
    position: absolute;
    top: 100%;
    right: auto;
    bottom: 0;
    left: 0;
    background: $white;
    width: 100%;
    height:100%;
    margin: 0;
    padding: 0;
    border-radius: 0 0 $r $r;
    display: none;
    @include chrisCoyier;

    .suggestion-list__item {
      position: relative;
      background: $white;
      margin: 0;
      padding: 0.5em 1em;
      list-style: none;
      font-family: $font-standard;
      color: $grey-text;
      border-top: 1px solid $grey;
      z-index: 90;
      box-shadow: $block-shadow;

      &:hover, &.selected {
        background: tint($grey,80%);
        color: $brand-color;
        cursor: pointer;
        box-shadow: 0 1px 1px rgba($black,0.1),0 4px 4px rgba($black,0.05), inset 2px 0 0 0 $brand-color;
      }

      &:first-child {
        border-top: 1px solid tint($grey, 80%);
      }

      &:last-child {
        border-radius: 0 0 $r $r;
      }
    }
  }
}
