.input-area {
  position: relative;
  background: url("/../assets/img/input-area-bg-mobile.jpg") no-repeat center 100%/cover;

  @include mq($mobileBreakpoint) {
    padding-bottom: 2em;
    background: url("/../assets/img/input-area-bg.jpg") no-repeat center 100%/cover;
  }

  @include mq($removeMobileMenuBreakPoint) {
    min-height: 52em;
  }


  @include mq($largeBreakpoint) {
    background: url("/../assets/img/input-area-bg-large.jpg") no-repeat center 100%/cover;
  }

  .input-wrapper {
    @extend .wrapper;
    position: relative;
    z-index: 1;
    padding: 0;

    @include mq($mobileBreakpoint) {
      min-height: 50em;
    }
  
    @include mq($removeMobileMenuBreakPoint) {
      display: flex;
      flex-flow: nowrap row;
      justify-content: space-between;
      align-items: flex-start;
    }

    @include mq($fourColBreakpoint) {
      justify-content: flex-start;
    }
  }

  .info-button {
    position: relative;
    margin: 0.65em 0 0 0.4em;
    background: none;
    border: none;
    width: 1.6em;
    min-width: 1.6em;
    max-width: 1.6em;
    height: 1.6em;
    min-height: 1.6em;
    max-height: 1.6em;
    background: transparent;
    border-radius: 50%;
    padding: 0.3em;
    background: $brand-color;
    cursor: pointer;

    @include mq($mobileBreakpoint) {
      margin-top: 0.2em;
    }

    &:hover svg {
      opacity: 0.8;
    }

    @at-root .intro-text .info-button {
      margin: 0 0 0 0.2em;

      .info-button__content {
        background: $white;
        padding: 0.6em;
        box-sizing: border-box;
        line-height: 125%;
        border: 1px solid rgba($black,0.1);
      }
    }

    svg path {
      fill: $white;
    }

    .info-button__content {
      display: block;
      position: absolute;
      top: 2em;
      right: auto;
      bottom: auto;
      left: -6em;
      margin: auto;
      min-width: 14em;
      box-shadow: $block-shadow-large;

      @include mq($smallMobileBreakpoint) {
        min-width: 17em;
      }

      @include mq($mobileBreakpoint) {
        min-width: 20em;
      }

      @include mq($removeMobileMenuBreakPoint) {
        min-width: 18em;
      }

      @include mq($largeBreakpoint) {
        min-width: 21em;
      }

      .info-button__content__text {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: 0.5em;
        box-sizing: border-box;
        background: $white;
        color: $black;
        text-align: left;
      }
    }
  }
}