/* MAIN MIXIN FILE */
//Shade function
@function shade($color, $percent) {
  @return mix(#000, $color, $percent);
}

//Tint function
@function tint($color, $percent) {
  @return mix(#fff, $color, $percent);
}

/* Media Query mixin */
@mixin mq($width, $type:'min') {
  @if($IE8 == false) {
    $width: $width / 16;
    @media all and (#{$type}-width: #{$width}em) {
      @content;
    }
  }
  @else
  {
    @if ($type == 'min' and $width <= 1200) {
      @content;
    }
  }
}

@mixin mqmax($width, $type:'max') {
  @if($IE8 == false) {
    $width: $width / 16;
    @media all and (#{$type}-width: #{$width}em) {
      @content;
    }
  }
}

@mixin isRetina() {
  @media
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    @content;
  }
}

@mixin chrisCoyier() {
  &:after {
    display: block;
    content: '';
    width: 100%;
    clear: both;
  }
}

@mixin btn($btn-color) {
  //Layout
  display: block;
  margin: 0 auto;
  border-radius: $r-btn;
  padding: 0.5em 1em;
  border: none;
  -webkit-appearance: none;

  //Typography
  font-family: $font-standard;
  font-size: 1.325em;
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;

  @include mq($mobileBreakpoint) {
    font-size: 2em;
    letter-spacing: 0.1em;
  }

  @include mq($largeBreakpoint) {
    font-size: 2.1em;
    letter-spacing: 0.1em;
  }

  //Color
  color: $bg-color-light;
  text-shadow: 0 1px 0 rgba(shade($btn-color,80%),0.4);
  background: $btn-color;
  box-shadow: inset 0 -1px tint($btn-color,2%), 0 1px rgba(shade($btn-color,50%),0.8), 0 -1px tint($btn-color,25%);

  //Interaction
  cursor: pointer;
  outline: none;
  transition: background 200ms ease;

  &:hover, &:focus {
    background: rgba($btn-color,0.8);
    box-shadow: inset 0 -1px tint($btn-color,30%), 0 1px 2px rgba(shade($btn-color,80%),0.5), 0 -1px tint($btn-color,25%);
    text-shadow: 0 1px 0 rgba(tint($btn-color,80%),0.3);
    transition: background 200ms ease;

    &[type=submit] {
    }
  }

  &.btn--disabled, &[disabled] {
    background: rgba(desaturate($brand-color,40%),0.35);
    color: rgba(desaturate($brand-color,40%),0.25);
    text-shadow: none;
    box-shadow: inset 0 -1px rgba(desaturate($brand-color,40%),0.2), 0 1px rgba(desaturate($brand-color,40%),0.4), 0 -1px rgba(desaturate($brand-color,40%),0.4);
    transition: background 200ms ease;

    &:hover {
      cursor: not-allowed;
    }
  }

  &.btn--calculating {
    overflow: hidden;
    position: relative;
    cursor: wait;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      min-width: 30em;
      width: 100%;
      height: 20em;
      background: rgba(shade($brand-color,50%),0.3);
      transform: translate3d(-100%,0,0);
      animation: loading-progress 10s infinite linear;
      z-index: 1;
    }

    &:hover {
      background: $brand-color;
    }
  }
}