.btn {
  //Layout
  display: block;
  margin: 0 auto;
  border-radius: 0;
  padding: 0.5em 1.5em;
  border: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  font-family: $font-bold;
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  color: $white;
  background: $brand-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //Interaction
  cursor: pointer;
  outline: none;
  transition: opacity 200ms ease;

  &:hover, &:focus {
    background: shade($brand-color, 10%);
    transition: opacity 200ms ease;
  }

  &.btn--disabled, &[disabled] {
    color: $grey;
    background: $brand-color;
    transition: opacity 200ms ease;

    &:hover {
      cursor: not-allowed;
      background: $brand-color;
    }
  }

  &.btn--white {
    background: $white;
    color: $brand-color;
  }

  &.btn--calculating {
    overflow: hidden;
    position: relative;
    cursor: wait;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      min-width: 30em;
      width: 100%;
      height: 20em;
      background: rgba(shade($brand-color,50%),0.3);
      transform: translate3d(-100%,0,0);
      animation: loading-progress 6s linear both;
      z-index: 1;
    }

    &:hover {
      background: $brand-color;
    }
  }
}