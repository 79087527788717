.main-footer {
    display: block;
    width: 100%;
    background: $grey-dark;

    .main-footer__content {
      @extend .wrapper;
      padding-top: 2em;
      padding-bottom: 2em;

      @include mq($mobileBreakpoint) {
        padding-top: 4em;
        padding-bottom: 4em;
      }

      .footer-flex {
        @include mq($mobileBreakpoint) {
          display: flex;
          flex-flow: nowrap row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }

      p {
        margin: 0 0 1em;
        font-size: 0.9em;
        line-height: 150%;
        color: $white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @include mq($mobileBreakpoint) {
          margin: 0 1em 0 0;
        }

        @include mq($removeMobileMenuBreakPoint) {
          font-size: 1em;
        }
      }

      img {
          display: block;
          max-width: 4em;

          @include mq($removeMobileMenuBreakPoint) {
            max-width: 4.5em;
          }
      }
    }
}