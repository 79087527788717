.input-field {
  position: relative;

  .loading {
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      bottom:0;
      z-index: 2;
      content: '';
      width: 500%;
      border-bottom: 3px dashed rgba($brand-color,0.5);
      animation: loading-progress-infinite 10s infinite linear;

      @include mq($removeMobileMenuBreakPoint) {
        bottom:1.2em;
      }
    }
  }

  &.datetime-picker input {
    cursor: pointer;
  }

  input {
    margin: 0 auto;
    display: block;
    padding: 1.4em 1em 0.6em;
    font-family: $font-standard;
    font-size: 1em;
    color: $black;
    background: $white;
    cursor: text;
    border: 1px solid transparent;
    border-radius: 0;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba($black,0.1),0 4px 4px rgba($black,0.05);
    transition: background 200ms ease, color 200ms ease, border 280ms ease;

    &::-webkit-input-placeholder {
      color: $black;
      transition: color 200ms ease;
    }

    &::-moz-placeholder {
      color: $black;
      transition: color 200ms ease;
    }

    &:-ms-input-placeholder {
      color: $black;
      transition: color 200ms ease;
    }

    @include mq($mobileBreakpoint) {
      font-size: 1.2em;
    }

    &:hover {
      background: shade($white,2%);
      color: $grey-dark-text;
      transition: background 200ms ease, color 200ms ease;

      &::-webkit-input-placeholder {
        color: $grey-dark-text;
        transition: color 200ms ease;
      }

      &::-moz-placeholder {
        color: $grey-dark-text;
        transition: color 200ms ease;
      }

      &:-ms-input-placeholder {
        color: $grey-dark-text;
        transition: color 200ms ease;
      }
    }

    &:focus {
      color: $grey-dark-text;
    }

    &:focus:hover {
      color: $grey-dark-text;
      background: $white;
      transition: background 200ms ease, color 200ms ease, border 280ms ease;
    }

    &.error {
      border-color: rgba($negative,0.8);
      color: desaturate($negative,40%);
      background: tint($negative,92%);
      transition: background 200ms ease, color 200ms ease, border 280ms ease;
      box-shadow: 0 1px 1px rgba($negative,0.1),0 4px 4px rgba($negative,0.05);

      &:hover {
        border-color: rgba($negative,0.5);
        background: tint($negative,95%);
        color: shade($grey-text,40%);
        transition: background 200ms ease, color 200ms ease;
      }

      &:focus {
        border-color: rgba($negative,0.5);
        color: desaturate($negative,40%);
      }
      &:focus:hover {
        border-color: rgba($negative,0.3);
        color: desaturate($negative,40%);
        background: $white;
        transition: background 200ms ease, color 200ms ease, border 280ms ease;
      }
    }


    &:focus ~ label, &.has-text ~ label {
      font-size: 0.8em;
      color: $brand-color;
      -webkit-font-smoothing: antialiased;
      transform: translate3d(0.5em,-0.3em,0);
      transition: transform 220ms ease, font-size 200ms ease;

      @include mq($mobileBreakpoint) {
        transform: translate3d(0.5em,-0.5em,0);
      }
    }

    &.success {
      border-color: rgba($positive,0.8);
      color: desaturate($positive,40%);
      background: tint($positive,92%);
      transition: background 200ms ease, color 200ms ease, border 280ms ease;
      box-shadow: 0 1px 1px rgba($positive,0.1),0 4px 4px rgba($positive,0.05);

      &:hover {
        border-color: rgba($positive,0.5);
        background: tint($positive,95%);
        color: shade($positive,40%);
        transition: background 200ms ease, color 200ms ease;
      }

      &:focus {
        border-color: rgba($positive,0.5);
        color: desaturate($positive,40%);
      }
      &:focus:hover {
        border-color: rgba($positive,0.3);
        color: desaturate($positive,40%);
        background: $white;
        transition: background 200ms ease, color 200ms ease, border 280ms ease;
      }
    }
  }

  label {
    position: absolute;
    top: 1em;
    left: 0.8em;
    color: $black;
    font-family: $font-standard;
    font-size: 1.2em;
    pointer-events: none;
    transition: transform 220ms ease, font-size 200ms ease;

    @include mq($mobileBreakpoint) {
      top: 1.25em;
      left: 1em;
    }
  }
}