a {
  font-family: $font-bold;
  outline: 0;
  text-decoration: underline;
  color: $brand-color;

  &:hover {
  }

  &.tel {
    color: inherit;
  }
}