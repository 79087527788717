@import "../config";

html, body {
  //overflow-x: hidden;
 // -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  background: $white;
}