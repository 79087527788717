.input-fields {
    margin: 0 auto;
    order: 1;
    flex: 1;
    padding: 2em 1em;
    box-sizing: border-box;
    background: rgba($black,0.6);

    @include mq($mobileBreakpoint) {
      padding: 2em;
      max-width: 37em;
      margin: 0 auto;
      background: rgba($black,0.76);
    }

    @include mq($removeMobileMenuBreakPoint) {
        order: 0;
        min-width: 40%;
        max-width: 40%;
        margin: 5em 0 0;
    }

    @include mq(1216) {
      min-width: 28.25em;
      max-width: 32.25em;
      margin-top: 7em;
    }

    .input-field {
      width: 100%;

      input {
        width: 100%;
        margin: 0 0 0.5em;

        @include mq($removeMobileMenuBreakPoint) {
          width: 100%;
          margin: 0 0 1em;
        }
      }
    }

    .drop-down {
      margin: 0 0 0.5em;

      @include mq($removeMobileMenuBreakPoint) {
        margin-bottom: 1em;
      }

      &:first-child .drop-down__select {
        padding: 1.548em 1em 0.8em;
      }
    }

    .two-rows {
      display: flex;
      flex-flow: wrap row;
      justify-content: flex-start;
      align-items: center;

        @include mq($mobileBreakpoint) {
          flex-flow: nowrap row;
        }

        @include mq($removeMobileMenuBreakPoint) {
          flex-flow: wrap row;
        }

        @include mq($largeBreakpoint) {
          flex-flow: nowrap row;
        }

        .checkbox-wrapper {
          display: flex;
          flex-flow: nowrap row;
          justify-content: flex-start;
          align-items: center;
          margin-right: 1.5em;
          min-width: 88px;
        }
    

        .label-checkbox {
          margin: 0 0;

          @include mq($mobileBreakpoint) {
            margin: -0.5em 0 0 0;
          }

          .label-checkbox__box {
            margin-top: -0.3em;
          }

          .label-checkbox__circle {
            display: none;
          }

          .label-checkbox__check {
            top: 10px;
          }
          
        }
    }

    .calc-info {
      display: block;
      width: 100%;
      margin: 1em 0 -0.5em;
      font-size: 0.78em;
      font-style: italic;
      line-height: 150%;
      color: $white;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @include mq($mobileBreakpoint) {
        margin: 1.8em 0 -0.5em;
      }
    }

    .calc-block {
      width: 100%;

        @include mq($mobileBreakpoint) {
          margin-left: auto;
          display: flex;
          flex-flow: wrap row;
          justify-content: flex-end;
          align-items: center;
        }

        .btn {
            width: 100%;
            margin: 1em 0 0;
            font-size: 1.2em;
            padding: 0.8em 1.5em;

            @include mq($mobileBreakpoint) {
              width: auto;
              margin: 0;
              padding: 0.75em 1.5em;
              font-size: 1.3em;
            }
        
            @include mq($removeMobileMenuBreakPoint) {
              min-width: 8em;
            }

            @include mq($largeBreakpoint) {
              min-width: 7em;
              margin: 0;
              display: block;
            }

            @include mq($fourColBreakpoint) {
              min-width: 8em;
            }
        }
    }
  }